let apiEndpointList = {
  COMPANY_MODULE_PROVIDER: {
    endpoint: "/v2/internal/module_provider",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MODULES: {
    endpoint: "/dashboard/modules",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PLAN_TYPE: {
    endpoint: "/v2/internal/plan_type",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PLAN_PRICING_TYPE: {
    endpoint: "/v2/internal/plan_pricing_type",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_DETAIL_DATA: {
    endpoint: "/v2/internal/company",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  UPDATE_COMPANY_DETAILS: {
    endpoint: "/v2/internal/company",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_SECRET_DATA: {
    endpoint: "/v2/internal/company_secret",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MODULE: {
    endpoint: "/v2/internal/company_module",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_MODULES_LATEST: {
    endpoint: "/decentro/read/fetch/module_details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  COMPANY_PROVIDER_SECRET: {
    endpoint: "/v2/internal/company_provider_secret",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_API_METER: {
    endpoint: "/v2/internal/company_api_meter",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_API_METER_SALAD: {
    endpoint: "/decentro/read/fetch/company_api_meter",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  SEND_ONBOARDING_CREDENTIALS: {
    endpoint: "/v2/email_company_credentials",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  MASTER_ACCOUNT_SETUP: {
    endpoint: "/v2/banking/account/master_setup",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_CREDITS_DETAILS: {
    endpoint: "/decentro/operations/fetch/credits_by_company_id",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_COMPANY_MODULE_DETAILS: {
    endpoint: "/decentro/operations/fetch/customer_modules",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  UPDATE_COMPANY_MODULE_DETAILS: {
    endpoint: "/v2/internal/company_module",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_COMPANY_CKYC_CONSUMER_GROUPS: {
    endpoint: "/decentro/operations/fetch/ckyc_group",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_COMPANY_DETAILS: {
    endpoint: "/decentro/operations/fetch/company_details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // Used under update merchant
  FETCH_COMPANY_DETAILS_SALAD: {
    endpoint: "/decentro/operations/company/details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  UPDATE_CKYC_CONSUMER_GROUP: {
    endpoint: "/v2/internal/company/consumer_groups",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CREATE_VA: {
    endpoint: "/v2/banking/account/virtual",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  ADD_DUMMY_MONEY: {
    endpoint: "/v2/internal/transaction/record",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  SEND_ONBOARDING_CREDENTIALS: {
    endpoint: "/v2/email_company_credentials",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  ACCOUNT_BALANCE: {
    endpoint: `/v2/banking/account`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_ALL_COMPANY_DETAIL: {
    endpoint: `/v2/internal/company`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_USER_TYPE_DROPDOWN: {
    endpoint: `/decentro/operations/fetch/user/types`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CONFIGURE_MVA: {
    endpoint: `/v2/banking/account/master_setup`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_HTTP_METHOD_TYPE: {
    endpoint: `/decentro/read/fetch/http_method_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // Merge conflict arrives here
  GET_COMPANY_AND_IP_DETAILS: {
    endpoint: "/decentro/read/fetch/company/customer_details",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_COMPANY_IP_DETAILS: {
    endpoint: "/decentro/read/fetch/ip/whitelisted",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  COMPANY_IP_ADDRESS_PUT: {
    endpoint: "/v2/internal/company/ip_address",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_CALLBACK_DETAILS: {
    endpoint: `/v2/company/callback`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_CALLBACK_TYPE: {
    endpoint: `/decentro/read/fetch/callback_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  ADD_NEW_CALLBACK: {
    endpoint: `/v2/internal/company/callback`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },

  GET_USER_TYPE: {
    endpoint: `/decentro/operations/fetch/user_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_DASHBOARD_USERS: {
    endpoint: `/decentro/operations/fetch/dashboard_users`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  MASTER_SEARCH_TRANSACTION: {
    endpoint: `/decentro/read/fetch/master/transaction`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  USER_REGISTER: {
    endpoint: `/user/register`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  USER_UPDATE_REGISTER: {
    endpoint: `/user/update`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_CALLBACK_VALUES: {
    endpoint: `/decentro/operations/fetch/provider/callback/data`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  USER_AUTH_INTERNAL: {
    endpoint: `/user/internal/auth`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  BANKING_ACCOUNT: {
    endpoint: `/v2/banking/account`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  AVERAGE_BANKING_ACCOUNT: {
    endpoint: `/decentro/read/account/balance/average`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_HTTP_METHOD_TYPE: {
    endpoint: `/decentro/read/fetch/http_method_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CALLBACK_TYPE: {
    endpoint: `/decentro/read/fetch/callback_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_VA: {
    endpoint: `/decentro/operations/search/virtual_account`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GOOGLE_LOGIN: {
    endpoint: `/user/internal/auth`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GENERATE_TOTP: {
    endpoint: `/v2/auth/user/otp`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  VERIFY_TOTP: {
    endpoint: `/v2/auth/user/otp/verify`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  MASTER_ACCOUNTS_ALIAS: {
    endpoint: `/decentro/read/fetch/dropdown/master_account_alias`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // Manage Transaction
  GET_COMPANY_COMMON_NAME_LIST: {
    endpoint: `/decentro/read/fetch/company_list`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  INITIATE_TRANSACTION_REVERSAL: {
    endpoint: `/internal/core_banking/money_transfer/initiate_reversal`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  MARK_TRANSACTION_SUCCESS: {
    endpoint: `/dashboard/admin/internal/transaction`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  RECORD_INCOMING_TRANSACTION: {
    endpoint: `/v2/internal/transaction/record`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  BULK_ACTION: {
    endpoint: `/v2/common/general_purpose_batch_job`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  FETCH_PROVIDERS_LIST: {
    endpoint: `/decentro/read/fetch/providers`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  REMITTER_WHITELIST: {
    endpoint: `/v2/banking/remitter`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  COMPANY_REMITTER_WHITELIST: {
    endpoint: `/v2/internal/company/remitter`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  SID_CONFIGURATION: {
    endpoint: `/v2/internal/payments/upi_sub_merchant_onboarding`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  UNLOAD_ACCOUNT: {
    endpoint: `/v2/internal/banking/virtual/account/unload`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  GET_CBA_RECORD: {
    endpoint: `/decentro/read/data/search/bank_account_number`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  UPLOAD_PROVIDER_STATEMENT: {
    endpoint: `/v2/internal/payments/record_provider_statement`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  BULK_PROCESSING_DATA: {
    endpoint: `/decentro/read/fetch/bulk_processing`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_PROCESSING_TYPE: {
    endpoint: `/decentro/read/fetch/bulk_processing_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_PROCESSING_COUNT: {
    endpoint: `/decentro/read/fetch/bulk_processing_count`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CHARGEBACK_DATA: {
    endpoint: `/decentro/read/fetch/chargeback`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CHARGEBACK_TYPE: {
    endpoint: `/decentro/read/fetch/chargeback_type`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  CHARGEBACK_COUNT: {
    endpoint: `/decentro/read/fetch/chargeback_count`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  BULK_ADD_CHARGEBACK: {
    endpoint: `/v2/internal/payments/bulk_add_chargeback`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  BULK_UPDATE_CHARGEBACK: {
    endpoint: `/v2/internal/payments/bulk_update_chargeback`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  SETTLEMENT_CYCLE: {
    endpoint: `/v2/common/internal/company_settlement_cycle`,
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  SETTLEMENT_CYCLE_URN: {
    endpoint: "/decentro/read/fetch/settlement_cycle",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  INVOKE_SETTLEMENT_BATCH_JOB: {
    endpoint: "/v2/common/internal/invoke_batch_process",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  VALIDATE_BANK_ACCOUNT: {
    endpoint: "/core_banking/money_transfer/validate_account",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  PROVIDER_ACCOUNTS: {
    endpoint: "/decentro/read/fetch/provider/dropdown/decentro_api",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  FETCH_MASTER_CBA_ALIAS: {
    endpoint: "/decentro/read/fetch/master_cba_alias",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  GET_IP_DATA: {
    endpoint: "/decentro/operations/fetch/ip/whitelisted",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  REQUEST_IP_WHITELIST: {
    endpoint: "/v2/admin/request_ip_whitelist",
    baseUrl:
      process.env.REACT_APP_ENV_NAME == "qa"
        ? process.env.REACT_APP_IP_ROUTE_STAG
        : process.env.REACT_APP_READ_API_ENDPOINT_BASE,
  },
  PAUSE_SETTLEMENT: {
    endpoint: "/v2/common/internal/config/pause_settlement",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  ADD_SETTLEMENT: {
    endpoint: "/v2/banking/account/settlement_account",
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
  },
  // used in all merchant data table
  ALL_CONSUMERS_DETAILS: {
    endpoint: `/decentro/operations/company/details`,
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
  // REPORTS
  CAS_REPORTS: {
    endpoint: "/decentro/operations",
    baseUrl: process.env.REACT_APP_READ_API_ENDPOINT,
  },
};

export default apiEndpointList;
