/* The `generateRandomPAN` function is a JavaScript function that generates a random PAN (Primary
Account Number). PANs are typically used in financial transactions and are a sequence of characters
that include a combination of letters and digits. */
function generateRandomPAN() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
  
    function getRandomCharFromString(str) {
      return str.charAt(Math.floor(Math.random() * str.length));
    }
  
    let pan = '';
  
    // First 5 characters are letters
    for (let i = 0; i < 5; i++) {
      pan += getRandomCharFromString(letters);
    }
  
    // Next 4 characters are digits
    for (let i = 0; i < 4; i++) {
      pan += getRandomCharFromString(digits);
    }
  
    // Last character is a letter
    pan += getRandomCharFromString(letters);
  
    return pan;
  }
  
export default generateRandomPAN;
  