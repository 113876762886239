import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import DateSelectorReports from "../../DateSelectorReports/DateSelectorReports";
import MultiInputField from "../../../UI/MultiInputField/MultiInputField";
import ResponseDetails from "../ResponseDetails/ResponseDetails";

// utils-import
import { formatDateReports } from "../../../utilities/dateFormatReports";
import { scrollIntoView } from "../../../utilities/scrollIntoView";

//styles-import
import "../../../UI/TextField/TextField.scss";

//regex-import
import { REGEXP } from "../../../utilities/validators/inputValidators";

// date-constant
let currentDate = new Date();
const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

let initialStartDate = new Date();
initialStartDate.setDate(initialStartDate.getDate() - 30);
let selectedDateRange = [initialStartDate, new Date()];

const initialValues = {
  company_name: "",
  provider_code: "",
  report_format: "",
  // report_type: hardcoded in the payload,
  date_range: [selectedDateRange[0], selectedDateRange[1]],
  emails: [],
};

const validationSchema = Yup.object({
  company_name: Yup.object().shape({
    value: Yup.number().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
  provider_code: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  report_format: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  date_range: Yup.array()
    .of(Yup.date().nullable())
    .required("Please select a date range"),
  emails: Yup.array()
    .of(
      Yup.string()
        .required("Email is required")
        .matches(REGEXP.emailRegex, "Invalid email address")
    )
    .max(3, "You can only enter up to 3 email addresses")
    .required("At least one email Address is required")
    .test(
      "at-least-one-email-address",
      "At least one email Address is required",
      (value) => value && value.length > 0
    ),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ReportForm = () => {
  const [providerList, setProviderList] = useState([""]);
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [isLoadingCompanyOptions, setIsLoadingCompanyOptions] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  // Response details Ref
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let payloadData;

      if (values?.provider_code?.label === "All") {
        payloadData = {
          company_id: values?.company_name?.value,
          start_date: formatDateReports(values.date_range[0]),
          end_date: formatDateReports(values.date_range[1]),
          // provider_id: values?.provider_code?.value,
          email_params: {
            to_emails: values.emails,
            common_name: values.company_name.label,
          },
        };
      } else {
        payloadData = {
          company_id: values?.company_name?.value,
          start_date: formatDateReports(values.date_range[0]),
          end_date: formatDateReports(values.date_range[1]),
          provider_id: values?.provider_code?.value,
          email_params: {
            to_emails: values.emails,
            common_name: values.company_name.label,
          },
        };
      }

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.CAS_REPORTS.baseUrl +
          apiEndpointList.CAS_REPORTS.endpoint +
          `/${values?.report_format?.value}/fetch/consolidated_account_statement/admin`,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={error?.response?.data || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  // console.log(formatDateReports(formik.values.date_range[0]));
  //& Fetching Providers to show in dropdown
  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.display_name,
        }));

        let allProviderOption = {
          value: 0,
          label: "All",
        };

        setProviderList([allProviderOption, ...options]);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  //& Fetching Companies to show in dropdown
  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoadingCompanyOptions(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoadingCompanyOptions(false);
      });
  };

  useEffect(() => {
    getProviderList();
    getCompanyOptions();
  }, []);

  const reportFormatList = [
    { value: "csv", label: "CSV" },
    { value: "xlsx", label: "XLSX" },
    { value: "json", label: "JSON" },
  ];

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-divider"></div>
          <div className="ui-form-content">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_name", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoadingCompanyOptions}
                placeholder="Select company name"
                isformatOptionLabel={true}
              />

              <TextFieldInput
                id="report_type"
                name="report_type"
                // onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={"CAS"}
                // touched={formik.touched.report_type}
                // error={formik.errors.report_type}
                placeholder="Enter report type"
                label="Report Type"
                required={true}
                disabled={true}
              />
            </div>

            {/* 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider"
                required={true}
                isLoading={isLoading}
                placeholder="Select provider"
                isformatOptionLabel={false}
              />
              <TextFieldSelect
                id="report_format"
                name="report_format"
                onChange={(selectedOption) =>
                  formik.setFieldValue("report_format", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("report_format", true)}
                value={formik.values.report_format}
                options={reportFormatList}
                noOptionsMessage={() => "No such report format exists"}
                label="Report Format"
                required={true}
                placeholder="Select report format"
              />
            </div>
            {/* 3 */}
            <div className="ui-form-inputs-section">
              <DateSelectorReports
                label="Date Range"
                // selectedDateRange={DateSelectorReports}
                selectedDateRange={formik.values.date_range}
                setSelectedDateRange={(event) => {
                  formik.setFieldValue("date_range", [event[0], event[1]]);
                }}
                required={true}
              />

              <MultiInputField
                id="emails"
                name="emails"
                className="multi-email-report"
                value={formik.values.emails}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                touched={formik.touched.emails}
                error={formik.errors.emails}
                placeholder={
                  formik.values.emails.length > 0
                    ? ""
                    : "Enter email Address(es)"
                }
                label="Email Address(es)"
                required={true}
                extraHint={
                  "Press Enter after entering each email Address. Max 3 email Addresses allowed"
                }
                elementsWidth="70%"
                singleFieldErrorText="Invalid email addresses"
              />
            </div>

            {/* Button Container */}
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>

      {/* Response Block */}
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportForm;
